import { type HeroFragment, ImagePosition } from '~/data/api'
import { cn } from '~/utils/cn'
import type { RenderableTreeNode } from '@markdoc/markdoc'
import { Markdown } from '~/components/cms/markdown'
import Cta from '~/components/cms/cta'

type HeroInputProps = Pick<
  HeroFragment,
  | 'headline'
  | 'headlineShort'
  | 'imagePosition'
  | 'text'
  | 'grid'
  | 'image'
  | 'cta'
>

type HeroProps = HeroInputProps & {
  markdownText: RenderableTreeNode
  markdownGrid: RenderableTreeNode[]
}

export function prepareHeroProps(data: HeroInputProps): HeroProps {
  return {
    ...(data as HeroProps),
  }
}

export default function Hero({
  headline,
  headlineShort,
  imagePosition,
  image,
  cta,
  markdownText,
  markdownGrid,
}: HeroProps) {
  const hasImage = !!image
  const hasImageOverlay = hasImage && imagePosition === ImagePosition.Background
  const hasImageFigure =
    hasImage &&
    (imagePosition === ImagePosition.Right ||
      imagePosition === ImagePosition.Left)

  return (
    <div className="hero relative -mx-8 w-screen bg-neutral bg-opacity-70 bg-gradient-to-b from-neutral-300 to-stone-400 text-neutral-content md:mx-0 md:w-auto md:rounded-lg">
      {hasImageOverlay && (
        <div
          className="absolute inset-0 rounded-lg bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${image.image1200})` }}
        >
          <div className="hero-overlay bg-opacity-70 md:rounded-lg" />
        </div>
      )}

      <div
        className={cn(
          'hero-content my-4 space-y-4 text-center md:my-16 md:space-y-16',
          hasImageFigure && 'flex-col md:flex-row',
          hasImageFigure &&
            imagePosition === ImagePosition.Right &&
            'lg:flex-row-reverse',
        )}
      >
        {hasImageFigure && (
          <picture>
            <source media="(max-width: 660px)" srcSet={image?.image600} />
            <source
              media="(min-width: 661px) and (max-width: 768px)"
              srcSet={image?.image1200}
            />
            <source media="(min-width: 769px)" srcSet={image?.image600} />
            <img
              src={image?.image600}
              alt=""
              className="rounded-lg shadow-2xl md:max-w-sm"
            />
          </picture>
        )}
        <div
          className={cn('flex flex-col items-center space-y-6 md:space-y-12')}
        >
          <div>
            <div className="hidden md:block">
              <h1
                className={cn(
                  'text-5xl font-semibold leading-tight text-white',
                  hasImageOverlay && 'text-white',
                )}
              >
                {headline}
              </h1>
            </div>
            <div className="md:hidden">
              <h1
                className={cn(
                  'text-3xl font-semibold leading-tight text-white',
                  hasImageOverlay && 'text-white',
                )}
              >
                {headlineShort || headline}
              </h1>
            </div>
          </div>
          {markdownText && (
            <Markdown
              content={markdownText}
              className={cn(
                hasImageOverlay &&
                  '!prose-gray !prose-invert !text-white marker:text-white prose-ul:mb-0',
                !hasImageFigure && 'max-w-4xl',
              )}
            />
          )}
          {markdownGrid.length > 0 && (
            <div
              className={cn(
                'md:grid md:grid-cols-2 md:gap-0',
                !hasImageFigure && 'max-w-4xl',
              )}
            >
              {markdownGrid.map((node, index) => (
                <Markdown
                  key={index}
                  content={node}
                  className={cn(
                    'text-left text-xl',
                    hasImageOverlay &&
                      '!prose-gray !prose-invert !text-white marker:text-white prose-ul:m-0',
                  )}
                />
              ))}
            </div>
          )}
          {cta && <Cta {...cta} />}
        </div>
      </div>
    </div>
  )
}
