import type { LoaderFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'

// @ts-ignore
export type UnwrapLoaderData<T extends LoaderFunction> = Awaited<
  // @ts-ignore
  ReturnType<Awaited<Awaited<ReturnType<T>>['json']>>
>

export const useUnwrappedLoaderData = <T extends LoaderFunction>() => {
  return useLoaderData() as UnwrapLoaderData<T>
}
