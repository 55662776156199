import type { Faq, FaqItem } from '~/data/api'

type FaqProps = {
  title: string | null | undefined
  items: Pick<FaqItem, 'question' | 'answer'>[]
}

export default function Faq({ items, title }: FaqProps) {
  return (
    <div className="flex justify-center">
      <div className="w-full space-y-6 lg:w-[48rem]">
        {title && (
          <h2 className="text-2xl font-bold lg:text-center">{title}</h2>
        )}
        {items.map((item, index) => (
          <details
            className="collapse collapse-plus rounded-lg bg-success"
            key={index}
          >
            <summary className="text-md collapse-title min-h-min font-medium">
              {item.question}
            </summary>
            <div className="collapse-content">
              <p>{item.answer}</p>
            </div>
          </details>
        ))}
      </div>
    </div>
  )
}
