import { cn } from '~/utils/cn'
import type { RenderableTreeNode } from '@markdoc/markdoc'
import { Markdown } from '~/components/cms/markdown'
import { type ContentBlockFragment, ImagePosition } from '~/data/api'
import Cta from '~/components/cms/cta'
import Steps from '~/components/cms/content-block-parts/steps'
import type { Results } from '~/domain/calc/products.server'
import ProductResults from '~/components/product-results'

type ContentBlockInputProps = Pick<
  ContentBlockFragment,
  | 'content'
  | 'imagePosition'
  | 'image'
  | 'cta'
  | 'productsList'
  | 'steps'
  | 'heading'
>

type ContentBlockProps = ContentBlockInputProps & {
  markdownText?: RenderableTreeNode
  sampleResults: Results
}

export function prepareContentBlockProps(
  data: ContentBlockInputProps,
  sampleResults: Results,
): ContentBlockProps {
  return {
    ...(data as ContentBlockProps),
    sampleResults,
  }
}

export default function ContentBlock({
  imagePosition,
  image,
  cta,
  markdownText,
  steps,
  productsList,
  sampleResults,
  heading,
}: ContentBlockProps) {
  const hasImage = !!image
  const hasImageOverlay = hasImage && imagePosition === ImagePosition.Background
  const hasImageFigure =
    hasImage &&
    (imagePosition === ImagePosition.Right ||
      imagePosition === ImagePosition.Left)

  return (
    <div className="relative space-y-8">
      {heading && (
        <h2 className="text-2xl font-bold lg:text-center">{heading}</h2>
      )}
      {hasImageOverlay && (
        <div
          className="absolute inset-0 rounded-lg bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${image.image1200})` }}
        >
          <div className="hero-overlay rounded-lg bg-opacity-60" />
        </div>
      )}

      {markdownText && (
        <div
          className={cn(
            'relative z-0 flex gap-6',
            hasImageOverlay && 'px-6',
            hasImageFigure && 'flex-col md:flex-row',
            hasImageFigure &&
              imagePosition === ImagePosition.Right &&
              'md:flex-row-reverse',
          )}
        >
          {hasImageFigure && (
            <picture>
              <source media="(max-width: 660px)" srcSet={image?.image600} />
              <source
                media="(min-width: 661px) and (max-width: 768px)"
                srcSet={image?.image1200}
              />
              <source media="(min-width: 769px)" srcSet={image?.image600} />
              <img
                src={image?.image600}
                alt=""
                className="rounded-lg shadow-2xl md:max-w-sm"
              />
            </picture>
          )}

          <div
            className={cn(
              'flex-1',
              !hasImageFigure && 'flex lg:justify-center',
            )}
          >
            <Markdown
              content={markdownText}
              className={cn(hasImageOverlay && '!prose-invert')}
            />
          </div>
        </div>
      )}
      {steps.length > 0 && <Steps items={steps} />}
      {productsList && <ProductResults results={sampleResults} />}
      {cta && (
        <div className="my-6 flex justify-center">
          <Cta {...cta} />
        </div>
      )}
    </div>
  )
}
