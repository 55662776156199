import type { RenderableTreeNode } from '@markdoc/markdoc'
import markdoc from '@markdoc/markdoc'
import React from 'react'
import { cn } from '~/utils/cn'

type Props = { content: RenderableTreeNode; className?: string }

export function Markdown({ content, className }: Props) {
  return (
    <div
      className={cn(
        className,
        'prose max-w-none prose-headings:mt-0 prose-ul:last:mb-0 lg:max-w-3xl prose-headings:lg:text-center',
      )}
    >
      {markdoc.renderers.react(content, React)}
    </div>
  )
}
