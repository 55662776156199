import type { CtaFragment } from '~/data/api'
import { Link } from '@remix-run/react'
import { cva } from 'class-variance-authority'

type CtaProps = Pick<CtaFragment, 'text' | 'url' | 'style' | 'size' | 'outline'>

const variants = cva('btn max-w-full', {
  variants: {
    style: {
      accent: 'btn-accent',
      error: 'btn-error',
      ghost: 'btn-ghost',
      info: 'btn-info',
      link: 'btn-link',
      neutral: 'btn-neutral',
      primary: 'btn-primary',
      secondary: 'btn-secondary',
      success: 'btn-success',
      warning: 'btn-warning',
    },
    size: {
      tiny: 'btn-xs',
      small: 'btn-sm',
      large: 'btn-lg',
      normal: '',
    },
    outline: {
      true: 'btn-outline',
    },
  },
  defaultVariants: {
    style: 'neutral',
    size: 'normal',
  },
})

export default function Cta({ text, url, style, size, outline }: CtaProps) {
  return (
    <Link className={variants({ style, size, outline })} to={url}>
      {text}
    </Link>
  )
}
