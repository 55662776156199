import React from 'react'

type Props = {
  items: string[]
}

function Steps({ items }: Props) {
  return (
    <ul className="steps steps-vertical flex-grow-0 lg:steps-horizontal lg:w-full">
      {items.map((item, index) => (
        <li key={index} className="step step-primary">
          <p className="text-left lg:text-center">{item}</p>
        </li>
      ))}
    </ul>
  )
}

export default Steps
